import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby'
import { Card, CardBody, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import Select from 'react-select'
import DashboardLayout from "../../layouts/dashboardlayout"
import { CheckToken, requestGetWithKey, requestPutId } from '../../helpers/utils'

const Wineries = () => {
  useEffect(() => {
    if (!CheckToken()) {
      navigate('/404')
    }
  }, [])

  const [wineryAccounts, setWineryAccounts] = useState([])
  const [selfWinery, setSelfWinery] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const [formatWinery, setFormatWinery] = useState(null)
  const [formats, setFormats] = useState([])
  const [selectedFormats, setSelectedFormats] = useState([])

  const toggle = () => setModal(!modal)

  const changeGraph = (data) => {
    if (data) {
      setSelfWinery(data)
    } else {
      setSelfWinery(null)
    }
  }

  const changeFormatWinery = (data) => {
    setFormatWinery(data)
    if (data) {
      fetchWineryFormats(data.value)
    } else {
      setSelectedFormats([])
    }
  }

  const fetchWineryFormats = async (wineryId) => {
    try {
      const response = await requestGetWithKey([`api/wineries/${wineryId}`])
      if (response) {
        const wineryFormats = response.message.formats.map(format => ({ value: format.codeName, label: format.name }))
        setSelectedFormats(wineryFormats)
      }
    } catch (error) {
      console.error('Error fetching winery formats:', error)
    }
  }

  const getFormatsList = async () => {
    try {
      const response = await requestGetWithKey([`api/formats`])
      if (response) {
        const formatOptions = response.message.map(format => ({ value: format.codeName, label: format.name }))
        setFormats(formatOptions)
      }
    } catch (error) {
      console.error('Error fetching formats list:', error)
    }
  }

  useEffect(() => {
    const getWineList = [`api/zoho/accounts`]
    requestGetWithKey(getWineList).then(result => {
      if (result) {
        let WinerycampaignList = result.message.map(d => ({ value: d.id, label: d.name }))
        setWineryAccounts(WinerycampaignList)
      }
    })
    const selfWineryList = [`api/zoho/accounts/self`]
    requestGetWithKey(selfWineryList).then(result => {
      if (result) {
        setSelfWinery({ value: result.message.data[0].id, label: result.message.data[0].Account_Name })
      }
    })
    getFormatsList()
  }, [])

  const searchWinery = async () => {
    if (selfWinery?.value) {
      try {
        const getSelectedWinery = [`api/zoho/accounts/${selfWinery.value}`]
        await requestPutId(getSelectedWinery)
        setModalMessage('Winery has changed Successfully')
        toggle()
      } catch (error) {
        setModalMessage('Error changing winery')
        toggle()
      }
    } else {
      setModalMessage('Please select a winery')
      toggle()
    }
  }

  const updateWineryFormats = async () => {
    if (formatWinery?.value) {
      try {
        const formatIds = selectedFormats ? selectedFormats.map(format => format.value) : [];
        const formats = {
          formats: formatIds
        };
        const putWineryFormats = [`api/wineries/${formatWinery.value}/formats`, formats];
        await requestPutId(putWineryFormats)
        setModalMessage('Winery formats updated successfully')
        toggle()
      } catch (error) {
        setModalMessage('Error updating winery formats')
        toggle()
      }
    } else {
      setModalMessage('Please select a winery')
      toggle()
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - Admin - Wineries</title>
      </Helmet>
      <DashboardLayout>
        <Card className="border-bottom adminSection" style={{ padding: '0px' }}>
          <CardBody className="overflow-hidden card-inside" style={{ height: 'unset', minHeight: '80vh' }}>
            <div className="row">
              <div className="col-6 back-img">
              </div>
              <div className="col-6 wineryDropdown">
                <h1>Change Winery Association</h1>
                <Select placeholder="Search wineries" options={wineryAccounts} value={selfWinery}
                  onChange={e => changeGraph(e)} isClearable />
                <Button
                  id="searchBtn1"
                  className="ml-auto px-5 btn-vino btn-vino-primary"
                  transform="down-1 shrink-4"
                  onClick={searchWinery}
                >
                  Let's go
                </Button>
                <hr className="styled-hr" />
                <h1>Manage Winery Export Formats</h1>
                <Select
                  placeholder="Select winery"
                  options={wineryAccounts}
                  value={formatWinery}
                  onChange={e => changeFormatWinery(e)}
                  isClearable
                />
                <Select
                  placeholder="Select export formats"
                  options={formats}
                  value={selectedFormats}
                  onChange={setSelectedFormats}
                  isMulti
                />
                <Button
                  id="updateFormatsBtn"
                  className="ml-auto px-5 btn-vino btn-vino-primary"
                  transform="down-1 shrink-4"
                  onClick={updateWineryFormats}
                >
                  Save
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </DashboardLayout>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h2>{modalMessage}</h2>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Wineries